import React from 'react';

const LoadingPopup = ({ message, type }) => {
    return (
        <div className="loading-popup-overlay">
            <div className={`loading-popup ${type}`}>
                <div className="loading-spinner"></div>
                <p>{message}</p>
            </div>
        </div>
    );
};

export default LoadingPopup;
