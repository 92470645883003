// images
import blogImg1 from "../images/blog/ai.webp";
import blogImg2 from "../images/blog/cloud.webp";
import blogImg3 from "../images/blog/Cyber.webp";
import blogImg4 from "../images/blog/blockchain.webp";
import blogImg5 from "../images/blog/iot.webp";
import blogImg6 from "../images/blog/5g.webp";

// Blog data
const blogs = [
    {
        id: '1',
        title: 'The Rise of AI and Machine Learning in Business',
        slug: 'The-Rise-of-AI-and-Machine-Learning-in-Business',
        screens: blogImg1,
        description: 'Explore how AI and ML are revolutionizing business processes and decision-making across industries.',
        author: 'Sarah Tech',
        authorTitle: 'Tech Enthusiast',
        create_at: '15 May 2023',
        comment: '50',
        thumb: 'AI & ML',
        blClass: 'format-standard-image',
    },
    {
        id: '2',
        title: 'Cloud Computing: Transforming IT Infrastructure',
        slug: 'Cloud-Computing-Transforming-IT-Infrastructure',
        screens: blogImg2,
        description: 'Discover how cloud computing is reshaping IT infrastructure and enabling scalable solutions for businesses.',
        author: 'Mike Cloud',
        authorTitle: 'Cloud Specialist',
        create_at: '10 May 2023',
        comment: '35',
        thumb: 'Cloud Technology',
        blClass: 'format-standard-image',
    },
    {
        id: '3',
        title: 'Cybersecurity in the Age of Remote Work',
        slug: 'Cybersecurity-in-the-Age-of-Remote-Work',
        screens: blogImg3,
        description: 'Learn about the latest cybersecurity challenges and solutions in the era of remote work and distributed teams.',
        author: 'Alex Secure',
        authorTitle: 'Cybersecurity Expert',
        create_at: '5 May 2023',
        comment: '40',
        thumb: 'Cybersecurity',
        blClass: 'format-standard-image',
    },
    {
        id: '4',
        title: 'Blockchain: Beyond Cryptocurrency',
        slug: 'Blockchain-Beyond-Cryptocurrency',
        screens: blogImg4,
        description: 'Explore the diverse applications of blockchain technology across various industries and sectors.',
        author: 'Emma Chain',
        authorTitle: 'Blockchain Specialist',
        create_at: '1 May 2023',
        comment: '28',
        thumb: 'Blockchain',
        blClass: 'format-standard-image',
    },
    {
        id: '5',
        title: 'IoT: Connecting the World Around Us',
        slug: 'IoT-Connecting-the-World-Around-Us',
        screens: blogImg5,
        description: 'Discover how the Internet of Things is creating smarter homes, cities, and industries.',
        author: 'David Connect',
        authorTitle: 'IoT Developer',
        create_at: '25 Apr 2023',
        comment: '45',
        thumb: 'IoT',
        blClass: 'format-standard-image',
    },
    {
        id: '6',
        title: '5G: The Future of Connectivity',
        slug: '5G-The-Future-of-Connectivity',
        screens: blogImg6,
        description: 'Explore the potential of 5G technology and its impact on various sectors, from healthcare to autonomous vehicles.',
        author: 'Lisa Speed',
        authorTitle: 'Telecom Expert',
        create_at: '20 Apr 2023',
        comment: '60',
        thumb: '5G Technology',
        blClass: 'format-standard-image',
    },
];

export default blogs;
