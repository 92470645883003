import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

import cnIcon1 from '../../../images/icons/icon_php.svg'
import cnIcon2 from '../../../images/icons/icon_javascript.svg'
import cnIcon4 from '../../../images/icons/icon_swift.svg'
import TypeScript from '../../../images/icons/icon_typescript.svg'
import cnIcon6 from '../../../images/icons/icon_python.svg'
import cnIcon8 from '../../../images/icons/icon_java.svg'
import cnIcon9 from '../../../images/icons/icon_ruby.svg'
import cnIcon10 from '../../../images/icons/icon_c_plus.svg'
import cnIcon11 from '../../../images/icons/icon_react_js.svg'
import cnIcon12 from '../../../images/icons/icon_laravel.svg'
import Bg from '../../../images/shapes/shape_title_under_line.svg'
import AWS from '../../../images/icons/icons8-amazon-aws-96.png'
// import GCP from '../../../images/icons/icon_gcp.svg'
import Azure from '../../../images/icons/icons8-azure-96.png'
import Docker from '../../../images/icons/icons8-docker-100.png'
// import Jenkins from '../../../images/icons/icon_jenkins.svg'
// import CircleCI from '../../../images/icons/icon_circleci.svg'
// import Git from '../../../images/icons/icon_git.svg'
import Angular from '../../../images/icons/icons8-angularjs-100.png'
import HTML from '../../../images/icons/icons8-html-96.png'
import CSS from '../../../images/icons/icons8-css-100.png'
import VUE from '../../../images/icons/icons8-vue-js-100.png'
import nextjs from '../../../images/icons/icons8-nextjs-100.png'
import bootstrap from '../../../images/icons/icons8-bootstrap-100.png'
import django from '../../../images/icons/icons8-django-100.png'
import express from '../../../images/icons/icons8-express-js-64.png'
import firebase from '../../../images/icons/icons8-firebase-100.png'
import graphql from '../../../images/icons/icons8-graphql-100.png'
import mongodb from '../../../images/icons/icons8-mongodb-96.png'
import mysql from '../../../images/icons/icons8-mysql-96.png'
import nodejs from '../../../images/icons/icons8-nodejs-100.png'
import postgresql from '../../../images/icons/icons8-postgresql-100.png'
import ruby from '../../../images/icons/icons8-ruby-on-rails-100.png'
import flutter from '../../../images/icons/icons8-flutter.svg'
import ArifEffect from '../../../main-component/Animations/ArifEffect';


const TechnologyList = [
    {
        Id: '1',
        sIcon: cnIcon1,
        title: 'PHP',
    },
    {
        Id: '2',
        sIcon: cnIcon2,
        title: 'JavaScript',
    },
    {
        Id: '3',
        sIcon: cnIcon11,
        title: 'ReactJs',
    },
    {
        Id: '4',
        sIcon: Angular,
        title: 'Angular',
    },
    {
        Id: '5',
        sIcon: cnIcon12,
        title: 'Laraval',
    },
    {
        Id: '6',
        sIcon: HTML,
        title: 'HTML',
    },
    {
        Id: '7',
        sIcon: CSS,
        title: 'CSS',
    },
    {
        Id: '8',
        sIcon: VUE,
        title: 'VUE',
    },
    {
        Id: '9',
        sIcon: nextjs,
        title: 'NextJS',
    },
    {
        Id: '10',
        sIcon: bootstrap,
        title: 'Bootstrap',
    },
    {
        Id: '11',
        sIcon: django,
        title: 'Django',
    },
    {
        Id: '12',
        sIcon: express,
        title: 'Express',
    },
    {
        Id: '13',
        sIcon: mongodb,
        title: 'MongoDB',
    },
    {
        Id: '14',
        sIcon: mysql,
        title: 'MySQL',
    },
    {
        Id: '15',
        sIcon: postgresql,
        title: 'PostgreSQL',
    },
    {
        Id: '16',
        sIcon: firebase,
        title: 'Firebase',
    },
    {
        Id: '17',
        sIcon: graphql,
        title: 'GraphQL',
    },
    {
        Id: '18',
        sIcon: nodejs,
        title: 'NodeJS',
    },
    {
        Id: '19',
        sIcon: ruby,
        title: 'Ruby',
    },
    {
        Id: '20',
        sIcon: django,
        title: 'Django',
    },
    {
        Id: '21',
        sIcon: AWS,
        title: 'AWS',
    },
    {
        Id: '22',
        sIcon: Azure,
        title: 'Azure',
    },
    {
        Id: '23',
        sIcon: Docker,
        title: 'Docker',
    },
    {
        Id: '24',
        sIcon: Angular,
        title: 'Angular',
    },
    {
        Id: '25',
        sIcon: django,
        title: 'Django',
    },
    {
        Id: '26',
        sIcon: cnIcon4,
        title: 'Swift',
    },
    {
        Id: '27',
        sIcon: cnIcon6,
        title: 'Python',
    },
    {
        Id: '28',
        sIcon: cnIcon8,
        title: 'Java',
    },
    {
        Id: '29',
        sIcon: cnIcon9,
        title: 'Ruby',
    },
    {
        Id: '30',
        sIcon: cnIcon10,
        title: 'C++',
    },
    {
        Id: '31',
        sIcon: cnIcon11,
        title: 'ReactNative',
    },
    {
        id: '32',
        sIcon: flutter,
        title: 'Flutter'
    },
    {
        Id: '33',
        sIcon: cnIcon12,
        title: 'Laraval',
    },
    {
        id: '34',
        sIcon: TypeScript,
        title: "TypeScript", 
    }
]


const TechnologySection = (props) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }


    return (

        <div className="section_space" id="process-technology">
            <div className="heading_block text-center">
                <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg})` }}>
                    <strong>The Tools We Trust</strong>
                </div>
                <h2 className="heading_text mb-0">
                Driving Success with <mark>Advanced Technologies</mark>
                </h2>
            </div>

            <div className="tab_block_wrapper">
                <Nav tabs className="nav justify-content-center">
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1'); }}
                        >
                            Web Technologies
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2'); }}
                        >
                            Databases Technologies
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '3' })}
                            onClick={() => { toggle('3'); }}
                        >

                            Cloud & DevOps
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '4' })}
                            onClick={() => { toggle('4'); }}
                        >

                            Mobile Apps
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '5' })}
                            onClick={() => { toggle('5'); }}
                        >

                            Other Frameworks
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className="web_development_technologies row justify-content-center">
                            {TechnologyList.slice(0, 12).map((technology, cnt) => (
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6" key={cnt}>
                                    <ArifEffect variants={{ peek: { opacity: 1, x: 0 }, hidden: { opacity: 0, x: -100 } }} transition = {{duration: 1, delay: 0.69 }}>
                                    <div className="iconbox_block text-center p-0 shadow-none bg-transparent">
                                        <div className="iconbox_icon">
                                            <img src={technology.sIcon} alt="" />
                                        </div>
                                            <button className="btn btn-3d blue-btn text-white" style={{lineHeight: '150%', fontSize: '96%', width: '56%'}}>{technology.title}</button>                                      
                                    </div>
                                    </ArifEffect>
                                </div>
                            ))}
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <div className="web_development_technologies row justify-content-center">
                            {TechnologyList.slice(12, 20).map((technology, cnt) => (
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6" key={cnt}>
                                    <div className="iconbox_block text-center p-0 shadow-none bg-transparent">
                                        <div className="iconbox_icon">
                                            <img src={technology.sIcon} alt="" />
                                        </div>
                                        <button className="btn btn-3d blue-btn text-white" style={{lineHeight: '150%', fontSize: '96%', width: '60%'}}>{technology.title}</button>                                      
                                    </div>
                                </div>
                            ))}
                        </div>
                    </TabPane>
                    <TabPane tabId="3">
                        <div className="web_development_technologies row justify-content-center">
                            {TechnologyList.slice(20, 26).map((technology, cnt) => (
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6" key={cnt}>
                                    <div className="iconbox_block text-center p-0 shadow-none bg-transparent">
                                        <div className="iconbox_icon">
                                            <img src={technology.sIcon} alt="" />
                                        </div>
                                        <button className="btn btn-3d blue-btn text-white" style={{lineHeight: '150%', fontSize: '96%', width: '56%'}}>{technology.title}</button>                                      
                                    </div>
                                </div>
                            ))}
                        </div>
                    </TabPane>
                    <TabPane tabId="4">
                        <div className="web_development_technologies row justify-content-center">
                            {TechnologyList.slice(25, 34).map((technology, cnt) => (
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6" key={cnt}>
                                    <div className="iconbox_block text-center p-0 shadow-none bg-transparent">
                                        <div className="iconbox_icon">
                                            <img src={technology.sIcon} alt="" />
                                        </div>
                                        <button className="btn btn-3d blue-btn text-white" style={{lineHeight: '150%', fontSize: '96%', width: '60%'}}>{technology.title}</button>                                      
                                    </div>
                                </div>
                            ))}
                        </div>
                    </TabPane>
                    <TabPane tabId="5">
                        <div className="web_development_technologies row justify-content-center">
                            {TechnologyList.slice(15, 26).map((technology, cnt) => (
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6" key={cnt}>
                                    <div className="iconbox_block text-center p-0 shadow-none bg-transparent">
                                        <div className="iconbox_icon">
                                            <img src={technology.sIcon} alt="" />
                                        </div>
                                        <button className="btn btn-3d blue-btn text-white" style={{lineHeight: '150%', fontSize: '96%', width: '56%'}}>{technology.title}</button>                                      
                                    </div>
                                </div>
                            ))}
                        </div>
                    </TabPane>
                </TabContent>
            </div>
        </div>
    )
}

export default TechnologySection;