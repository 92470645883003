import React from 'react';
import { Link } from 'react-router-dom'
import Project from '../../../api/project'
import Bg from '../../../images/backgrounds/bg_image_2.webp'
import Bg3 from '../../../images/shapes/shape_title_under_line.svg'
import shape1 from '../../../images/shapes/shape_space_2.svg'


const casestudy = (props) => {

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  }

  return (
    <section className="about_and_case_section section_decoration bg-dark" style={{ backgroundImage: `url(${Bg})`,paddingTop: '100px',paddingLeft: '4%',paddingRight: '4%' }}>
      <div className="container">
        <div>
          <div className="heading_block text-center text-white">
            <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg3})` }}>
              <strong>Client Evaluations</strong>
            </div>
            <h2 className="heading_text mb-0">
            Invecent's Recent Client Success Stories
            </h2>
          </div>

          <div className="case_studies_wrapper">
            {Project.slice(0, 3).map((project, prj) => (
              <div className="case_study_block" key={prj}>
                <div className="case_study_image">
                  <img src={project.pImg} alt="Invecent - Cases" />
                </div>
                <div className="case_study_content">
                  <ul className="category_list unordered_list text-uppercase">
                    <li><a href="portfolio.html">{project.sub}</a></li>
                  </ul>
                  <h3 className="case_title">
                    <Link onClick={ClickHandler} to={`/portfolio_details/${project.slug}`}>{project.title}</Link>
                  </h3>
                  <p>
                    {project.description}
                  </p>
                  <ul className="icon_list unordered_list">
                    <li>
                      <span className="icon_list_text">
                        <strong className="text-dark">Industry:</strong> {project.Industry}
                      </span>
                    </li>
                    <li>
                      <span className="icon_list_text">
                        <strong className="text-dark">Country:</strong> {project.Country}
                      </span>
                    </li>
                  </ul>
                  <ul className="case_technologies unordered_list" data-text="Core Technologies:">
                    <li>
                      <img src={project.Technologies1} alt="Angular" />
                    </li>
                    <li>
                      <img src={project.Technologies2} alt="Elephent" />
                    </li>
                  </ul>
                  <Link onClick={ClickHandler} to={`/portfolio_details/${project.slug}`} className="btn btn-primary">
                    <span className="btn_label" data-text="Read Case">Read Case</span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </Link>
                </div>
              </div>
            ))}
          </div>

          <div className="btns_group pb-0">
            <Link onClick={ClickHandler} to="/portfolio" className="btn btn-primary">
              <span className="btn_label" data-text="View More Cases Study">View More Cases Study</span>
              <span className="btn_icon">
                <i className="fa-solid fa-arrow-up-right"></i>
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="decoration_item shape_image_1">
        <img src={shape1} alt="Invecent Shape" />
      </div>
    </section>
  )
}

export default casestudy;