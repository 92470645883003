import React from 'react';
import useMetaTags from '../../hooks/useMetaTags';
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics';

/**
 * SEOWrapper component that combines meta tags and analytics
 * @param {Object} props - Component props
 * @param {Object} props.metaData - Meta tag data
 * @param {React.ReactNode} props.children - Child components
 */
const SEOWrapper = ({ metaData, children }) => {
    const metaTags = useMetaTags(metaData);
    const { trackEvent } = useGoogleAnalytics();

    return (
        <>
            {metaTags}
            {children}
        </>
    );
};

export default SEOWrapper;
