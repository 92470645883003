import React, { Fragment, useState } from 'react';
import SEOWrapper from '../../components/SEOWrapper/SEOWrapper';
import Collaborations from '../../api/Collaboration'
import { useParams } from 'react-router-dom'
import ModalVideo from 'react-modal-video'
import Header2 from '../../components/Header2/Header2';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
// import icon from '../../images/icons/icon_check_3.svg'
import ContactSection from '../../components/software-company-components/ContactSection';
import FaqSection from '../../components/software-company-components/FaqSection/FaqSection';

const CollaborationsPage = () => {

    const { slug } = useParams();

    const [isOpen, setOpen] = useState(false);

    const CollaborationDetails = Collaborations.find(item => item.slug === slug)

    const metaData = {
        title: `${CollaborationDetails.title} | Invecent Private Limited`,
        description: `Explore our strategic partnership with ${CollaborationDetails.title}. ${CollaborationDetails.description.slice(0, 150)}...`,
        keywords: `${CollaborationDetails.title.toLowerCase()}, partnerships, collaborations, strategic alliances, joint ventures, technology partnerships, business collaborations, innovation partnerships`,
        additionalTags: {
            'og:type': 'website',
            'article:section': `${CollaborationDetails.title} Partnership`,
            'og:locale': 'en_US'
        }
    };

    return (
        <SEOWrapper metaData={metaData}>
            <Fragment>
                <Header2 />
                <main className="page_content service-single-page">
                    <PageTitle pageTitle={CollaborationDetails.title} pagesub={'Details'} pageTop={'Services'}/>
                    <section className="service_details_section section_space bg-light">
                        <div className="container">
                            <div className="row">
                            {CollaborationDetails.image.map((image, featureitem) => (

                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="details_item_image m-0">
                                        <img src={image} alt="Service Details" style={{height: '400px'}}/>
                                    </div>
                                </div>
                            ))}
                            </div>
                            <br/>
                            <h2 className="details_item_title">{CollaborationDetails.title}</h2>
                            <p>
                                {CollaborationDetails.description}
                            </p>
                            <p>
                            At our IT solution agency, we specialize in crafting robust and reliable network architectures tailored to meet the unique needs of our clients. From small businesses to large enterprises, we understand the critical importance of a well-designed network infrastructure in driving efficiency, security, and scalability. Our team of experienced professionals works closely with clients.
                            </p>
                        </div>
                    </section>
                    <FaqSection />
                    <ContactSection />
                </main>
                <Footer />
                <Scrollbar />
                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="7e90gBu4pas" onClose={() => setOpen(false)} />
            </Fragment>
        </SEOWrapper>
    )
};
export default CollaborationsPage;