import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';
import SEOWrapper from '../SEOWrapper/SEOWrapper';
import Header2 from '../Header2/Header2';
import Footer from '../footer/Footer';
import Scrollbar from '../scrollbar/scrollbar';
import PageTitle from '../pagetitle/PageTitle';
import LoadingPopup from '../common/LoadingPopup';
import ArifEffect from '../../main-component/Animations/ArifEffect';

const ITForm = () => {
    const [submitStatus, setSubmitStatus] = useState({ success: false, error: null });
    const [loadingState, setLoadingState] = useState({ show: false, message: '', type: 'loading' });

    const itPositions = [
        {
            title: "Full Stack Developer",
            experience: "3-8 years",
            type: "Full-time",
            location: "Hybrid",
            skills: ["React", "Node.js", "MongoDB", "AWS"]
        },
        {
            title: "DevOps Engineer",
            experience: "4-10 years",
            type: "Full-time",
            location: "Remote",
            skills: ["Docker", "Kubernetes", "Jenkins", "AWS"]
        },
        {
            title: "Data Scientist",
            experience: "3-7 years",
            type: "Full-time",
            location: "On-site",
            skills: ["Python", "Machine Learning", "SQL", "TensorFlow"]
        },
        {
            title: "Cloud Architect",
            experience: "5-12 years",
            type: "Full-time",
            location: "Hybrid",
            skills: ["AWS", "Azure", "GCP", "Terraform"]
        }
    ];

    const validationSchema = Yup.object().shape({
        fullName: Yup.string()
            .required('Full name is required')
            .min(2, 'Name must be at least 2 characters'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        phone: Yup.string()
            .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
            .required('Phone number is required'),
        position: Yup.string()
            .required('Position is required'),
        experience: Yup.string()
            .required('Experience details are required'),
        currentCompany: Yup.string()
            .required('Current company is required'),
        skills: Yup.string()
            .required('Technical skills are required'),
        portfolio: Yup.string()
            .url('Must be a valid URL')
            .required('Portfolio/GitHub link is required'),
        linkedin: Yup.string()
            .url('Must be a valid LinkedIn URL')
            .required('LinkedIn profile is required'),
        noticePeriod: Yup.string()
            .required('Notice period is required'),
        expectedSalary: Yup.string()
            .required('Expected salary is required'),
        coverLetter: Yup.string()
            .min(50, 'Cover letter must be at least 50 characters')
            .required('Cover letter is required')
    });

    const initialValues = {
        fullName: '',
        email: '',
        phone: '',
        position: '',
        experience: '',
        currentCompany: '',
        skills: '',
        portfolio: '',
        linkedin: '',
        noticePeriod: '',
        expectedSalary: '',
        coverLetter: ''
    };

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setLoadingState({ show: true, message: 'Submitting your application...', type: 'loading' });
        try {
            const response = await emailjs.send(
                'YOUR_SERVICE_ID',
                'YOUR_TEMPLATE_ID',
                {
                    from_name: values.fullName,
                    from_email: values.email,
                    phone: values.phone,
                    position: values.position,
                    experience: values.experience,
                    current_company: values.currentCompany,
                    skills: values.skills,
                    portfolio: values.portfolio,
                    linkedin: values.linkedin,
                    notice_period: values.noticePeriod,
                    expected_salary: values.expectedSalary,
                    cover_letter: values.coverLetter
                },
                'YOUR_PUBLIC_KEY'
            );

            if (response.status === 200) {
                setSubmitStatus({ success: true, error: null });
                setLoadingState({ show: true, message: 'Application submitted successfully!', type: 'success' });
                resetForm();
                setTimeout(() => {
                    setLoadingState({ show: false, message: '', type: 'loading' });
                }, 2000);
            }
        } catch (error) {
            setSubmitStatus({ success: false, error: 'Failed to send application. Please try again.' });
            setLoadingState({ 
                show: true, 
                message: 'There was an error submitting your application. Please wait while we try to resolve it.',
                type: 'error'
            });
            setTimeout(() => {
                setLoadingState({ show: false, message: '', type: 'loading' });
            }, 3000);
        }
        setSubmitting(false);
    };

    const metaData = {
        title: "IT Career Opportunities | Invecent Private Limited",
        description: "Apply for exciting IT career opportunities at Invecent Private Limited. Join our innovative tech team and work on cutting-edge projects.",
        keywords: "IT jobs, tech careers, software jobs, developer positions, IT careers, tech opportunities",
        additionalTags: {
            'og:type': 'website',
            'article:section': 'Careers',
            'og:locale': 'en_US'
        }
    };

    return (
        <SEOWrapper metaData={metaData}>
            {loadingState.show && (
                <LoadingPopup message={loadingState.message} type={loadingState.type} />
            )}
            <Header2 />
            <main className="page_content">
            <PageTitle pageTitle={'IT Career Opportunities'} pagesub={'Our Tech Team'}  pageTop={'Join'} />
            
            <div className="non-it-jobs-section section_space">
                <div className="container">
                    {/* Open Positions Section */}
                    <div className="row mb-5">
                        <div className="col-12">
                            <div className="section_heading text-center mb-5">
                                <h2 className="heading_text">Current IT Openings</h2>
                                <p className="heading_description">
                                    Join our innovative tech team and shape the future of technology
                                </p>
                            </div>
                        </div>
                        
                        {itPositions.map((position, index) => (
                            <div className="col-lg-3 col-md-6 mb-4" key={index}>
                                <ArifEffect>
                                <div className="position-card">
                                    <div className="card-body">
                                        <h3 className="position-title">{position.title}</h3>
                                        <div className="position-details">
                                            <p><i className="fas fa-briefcase"></i> {position.experience}</p>
                                            <p><i className="fas fa-clock"></i> {position.type}</p>
                                            <p><i className="fas fa-map-marker-alt"></i> {position.location}</p>
                                        </div>
                                        <div className="skills-section">
                                            <h4>Required Skills:</h4>
                                            <div className="skills-list">
                                                {position.skills.map((skill, idx) => (
                                                    <span key={idx} className="skill-tag">{skill}</span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </ArifEffect>
                            </div>
                        ))}
                    </div>

                    {/* Why Join Us Section */}
                    <div className="row mb-5">
                        <div className="col-12">
                            <div className="benefits-section">
                                <h2 className="text-center mb-4">Why Join Our Tech Team?</h2>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="benefit-item">
                                            <i className="fas fa-laptop-code"></i>
                                            <h3>Cutting-Edge Tech</h3>
                                            <p>Work with the latest technologies and tools</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="benefit-item">
                                            <i className="fas fa-brain"></i>
                                            <h3>Innovation Culture</h3>
                                            <p>Freedom to experiment and innovate</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="benefit-item">
                                            <i className="fas fa-rocket"></i>
                                            <h3>Career Growth</h3>
                                            <p>Fast-track your tech career with us</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Application Form */}
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="application-section">
                                <h2 className="text-center mb-4">Apply Now</h2>
                                <div className="application-form-wrapper">
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ errors, touched, isSubmitting }) => (
                                            <Form className="it-form">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <Field
                                                                type="text"
                                                                name="fullName"
                                                                placeholder="Full Name *"
                                                                className={`form-control ${errors.fullName && touched.fullName ? 'is-invalid' : ''}`}
                                                            />
                                                            {errors.fullName && touched.fullName && (
                                                                <div className="invalid-feedback">{errors.fullName}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <Field
                                                                type="email"
                                                                name="email"
                                                                placeholder="Email Address *"
                                                                className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                                                            />
                                                            {errors.email && touched.email && (
                                                                <div className="invalid-feedback">{errors.email}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <Field
                                                                type="tel"
                                                                name="phone"
                                                                placeholder="Phone Number *"
                                                                className={`form-control ${errors.phone && touched.phone ? 'is-invalid' : ''}`}
                                                            />
                                                            {errors.phone && touched.phone && (
                                                                <div className="invalid-feedback">{errors.phone}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <Field
                                                                as="select"
                                                                name="position"
                                                                className={`form-control ${errors.position && touched.position ? 'is-invalid' : ''}`}
                                                            >
                                                                <option value="">Select Position *</option>
                                                                {itPositions.map((pos, index) => (
                                                                    <option key={index} value={pos.title}>
                                                                        {pos.title}
                                                                    </option>
                                                                ))}
                                                            </Field>
                                                            {errors.position && touched.position && (
                                                                <div className="invalid-feedback">{errors.position}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <Field
                                                                type="text"
                                                                name="experience"
                                                                placeholder="Years of Experience *"
                                                                className={`form-control ${errors.experience && touched.experience ? 'is-invalid' : ''}`}
                                                            />
                                                            {errors.experience && touched.experience && (
                                                                <div className="invalid-feedback">{errors.experience}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <Field
                                                                type="text"
                                                                name="currentCompany"
                                                                placeholder="Current Company *"
                                                                className={`form-control ${errors.currentCompany && touched.currentCompany ? 'is-invalid' : ''}`}
                                                            />
                                                            {errors.currentCompany && touched.currentCompany && (
                                                                <div className="invalid-feedback">{errors.currentCompany}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <Field
                                                                type="text"
                                                                name="skills"
                                                                placeholder="Technical Skills *"
                                                                className={`form-control ${errors.skills && touched.skills ? 'is-invalid' : ''}`}
                                                            />
                                                            {errors.skills && touched.skills && (
                                                                <div className="invalid-feedback">{errors.skills}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <Field
                                                                type="url"
                                                                name="portfolio"
                                                                placeholder="Portfolio/GitHub Link *"
                                                                className={`form-control ${errors.portfolio && touched.portfolio ? 'is-invalid' : ''}`}
                                                            />
                                                            {errors.portfolio && touched.portfolio && (
                                                                <div className="invalid-feedback">{errors.portfolio}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <Field
                                                                type="url"
                                                                name="linkedin"
                                                                placeholder="LinkedIn Profile *"
                                                                className={`form-control ${errors.linkedin && touched.linkedin ? 'is-invalid' : ''}`}
                                                            />
                                                            {errors.linkedin && touched.linkedin && (
                                                                <div className="invalid-feedback">{errors.linkedin}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <Field
                                                                type="text"
                                                                name="noticePeriod"
                                                                placeholder="Notice Period *"
                                                                className={`form-control ${errors.noticePeriod && touched.noticePeriod ? 'is-invalid' : ''}`}
                                                            />
                                                            {errors.noticePeriod && touched.noticePeriod && (
                                                                <div className="invalid-feedback">{errors.noticePeriod}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <Field
                                                                type="text"
                                                                name="expectedSalary"
                                                                placeholder="Expected Salary *"
                                                                className={`form-control ${errors.expectedSalary && touched.expectedSalary ? 'is-invalid' : ''}`}
                                                            />
                                                            {errors.expectedSalary && touched.expectedSalary && (
                                                                <div className="invalid-feedback">{errors.expectedSalary}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <Field
                                                                as="textarea"
                                                                name="coverLetter"
                                                                placeholder="Cover Letter *"
                                                                className={`form-control ${errors.coverLetter && touched.coverLetter ? 'is-invalid' : ''}`}
                                                                rows="4"
                                                            />
                                                            {errors.coverLetter && touched.coverLetter && (
                                                                <div className="invalid-feedback">{errors.coverLetter}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 text-center">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary submit-btn"
                                                            disabled={isSubmitting}
                                                        >
                                                            {isSubmitting ? 'Submitting...' : 'Submit Application'}
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>

                                    {submitStatus.success && (
                                        <div className="alert alert-success mt-4" role="alert">
                                            Your application has been submitted successfully! We'll review it and get back to you soon.
                                        </div>
                                    )}
                                    {submitStatus.error && (
                                        <div className="alert alert-danger mt-4" role="alert">
                                            {submitStatus.error}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </main>
            <Footer />
            <Scrollbar />
        </SEOWrapper>
    );
};

export default ITForm;
