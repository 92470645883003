import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import HomePage2 from '../HomePage2/HomePage2';
import AboutUsPage from '../AboutUsPage/AboutUsPage';
import TeamSinglePage from '../TeamSinglePage/TeamSinglePage';
import ServicePage from '../ServicePage/ServicePage';
import ServiceSinglePage from '../ServiceSinglePage/ServiceSinglePage';
import BlogDetails from '../BlogDetails/BlogDetails';
import ContactPage from '../ContactPage/ContactPage';
import CareerPage from '../Careers/Careers';
import ITStaffing from '../../components/software-company-components/ITStaffing/ITStaffing';
import TechnologiesPage from '../TechnologiesPage/TechnologiesPage';
import CSRPage from '../CSR/CSR';
import CollaborationsPage from '../CollaborationsPage/CollaborationsPage';
import ITForm from '../../components/ITForm/ITForm';
import GeneralForm from '../../components/GeneralForm/GeneralForm';
import InternshipPage from '../../components/InternshipPage/InternshipPage';

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage2 />} />
          <Route path="home" element={<HomePage2 />} />
          <Route path="about" element={<AboutUsPage />} />
          <Route path="team-single/:slug" element={<TeamSinglePage />} />
          <Route path="service" element={<ServicePage />} />
          <Route path="service-single/:slug" element={<ServiceSinglePage />} />
          <Route path="blog-single/:slug" element={<BlogDetails />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="careers" element={<CareerPage />} />   
          <Route path="itstaffing" element={<ITStaffing />} />    
          <Route path="Technologies" element={<TechnologiesPage />} />
          <Route path="CSR" element={<CSRPage />} />
          <Route path="collaborations/:slug" element={<CollaborationsPage />} />
          <Route path="ITForm" element={<ITForm />} />
          <Route path="NonITForm" element={<GeneralForm />} />
          <Route path="internship" element={<InternshipPage />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
