import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GA_MEASUREMENT_ID = 'G-GTQXKHQXR5'; // Your GA4 Measurement ID

const useGoogleAnalytics = () => {
    const location = useLocation();

    useEffect(() => {
        // Initialize Google Analytics
        if (typeof window.gtag !== 'function') {
            // Add Google Analytics script if not already present
            const script = document.createElement('script');
            script.async = true;
            script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
            document.head.appendChild(script);

            window.dataLayer = window.dataLayer || [];
            function gtag() {
                window.dataLayer.push(arguments);
            }
            window.gtag = gtag;
            gtag('js', new Date());
            gtag('config', GA_MEASUREMENT_ID);
        }
    }, []);

    // Track page views
    useEffect(() => {
        if (typeof window.gtag === 'function') {
            window.gtag('config', GA_MEASUREMENT_ID, {
                page_path: location.pathname + location.search
            });
        }
    }, [location]);

    // Function to track custom events
    const trackEvent = (eventName, eventParams = {}) => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', eventName, eventParams);
        }
    };

    return { trackEvent };
};

export default useGoogleAnalytics;
