import React, { Fragment, useState } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { Link } from "react-router-dom";

const menus = [
    {
        id: 1,
        title: 'Home',
        link: '/HomePage2',
    },
    {
        id: 2,
        title: 'Company',
        link: '/HomePage2',
        submenu: [
            {
                id: 1,
                title: 'IT Staffing',
                link: '/itstaffing',
            },
            {
                id: 1,
                title: 'About Us',
                link: '/about',
            },  
        ]
    },
    {
        id: 3,
        title: 'Services',
        link: '/service',
        submenu: [
            {
                id: 1,
                title: 'Web App Development',
                link: '/service-single/Web-Application-Development',
            },
            {
                id: 2,
                title: 'Mobile App Development',
                link: '/service-single/Mobile-Application-Development'
            },
            {
                id: 3,
                title: 'Enterprise Application',
                link: '/service-single/Enterprise-Application'
            },
            {
                id: 4,
                title: 'UI UX Designing',
                link: '/service-single/UI-UX-Designing'
            },
            {
                id: 5,
                title: 'Digital Marketing',
                link: '/service-single/Digital-Marketing'
            },
            {
                id: 6,
                title: 'DataScience & GenAI',
                link: '/service-single/DataScience-GenAI'
            }
        ]
    },
    {
        id: 4,
        title: 'Technologies',
        link: '/Technologies',
        submenu: [
            {
                id: 1,
                title: 'Web Technologies',
                link: '/Technologies'
            },
            {
                id: 2,
                title: 'Backend Technologies',
                link: '/Technologies'
            },
            {
                id: 3,
                title: 'Cloud Technologies',
                link: '/Technologies'
            }
        ]
    },
    {
        id: 5,
        title: 'Collaborations',
        link: '/contact',
        submenu: [
            {
                id: 1,
                title: 'Universities',
                link: '/collaborations/Universities'
            },
            {
                id: 2,
                title: 'Franchies',
                link: '/collaborations/Franchies'
            },
            {
                id: 3,
                title: 'Companies',
                link: '/collaborations/Companies'
            },
            {
                id: 4,
                title: 'StartUps',
                link: '/collaborations/StartUps'
            },
            {
                id: 5,
                title: 'Investors',
                link: '/collaborations/Investors'
            }
        ]
    },
    {
        id: 6,
        title: 'Careers',
        link: '/careers',
        submenu: [
            {
                id: 1,
                title: 'IT Jobs',
                link: '/ITForm'
            },
            {
                id: 2,
                title: 'Non IT Jobs',
                link: '/NonITForm'
            },
            {
                id: 3,
                title: 'Internships',
                link: '/internship'
            }
        ]
    },
    {
        id: 7,
        title: 'CSR',
        link: '/CSR'
    },
    {
        id: 8,
        title: 'Contact',
        link: '/contact'
    }


]

const MobileMenu = () => {

    const [openId, setOpenId] = useState(0);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <ul className="main_menu_list clearfix">
            {menus.map((item, mn) => {
                return (
                    <ListItem className={item.id === openId ? 'active' : null} key={mn}>
                        {item.submenu ?
                            <Fragment>
                                <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>{item.title}
                                    <i className={item.id === openId ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i>
                                </p>
                                <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                                    <List className="subMenu">
                                        <Fragment>
                                            {item.submenu.map((submenu, i) => {
                                                return (
                                                    <ListItem key={i}>
                                                        <Link onClick={ClickHandler} className="active"
                                                            to={submenu.link}>{submenu.title}</Link>
                                                    </ListItem>
                                                )
                                            })}
                                        </Fragment>
                                    </List>
                                </Collapse>
                            </Fragment>
                            : <Link className="active"
                                to={item.link}>{item.title}</Link>
                        }
                    </ListItem>
                )
            })}
        </ul>
    )
}

export default MobileMenu;