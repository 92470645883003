import a from '../images/collaborations/ch.png'
import b from '../images/collaborations/city.png'
import c from '../images/collaborations/micnew.png'
import d from '../images/collaborations/sridatta.webp'
import e from '../images/collaborations/sriindu.webp'

const Collaboration = [
    {
        title: 'Universities',
        slug: 'Universities',
        description: 'Partner with Invecent PVT LTD to foster innovation and provide students with industry-relevant opportunities through research and development programs.',
        link: 'https://www.google.com',
        image: [a,b,c,d,e],
    },
    {
        title: 'Franchise',
        slug: 'Franchise',
        description: 'Join hands with Invecent PVT LTD to expand your franchise network and unlock new markets with innovative business solutions.',
        link: 'https://www.google.com',
        image: [b],
    },
    {
        title: 'Companies',
        slug: 'Companies',
        description: 'Collaborate with Invecent PVT LTD to drive growth, streamline processes, and achieve business excellence through tailored solutions.',
        link: 'https://www.google.com',
        image: [e],
    },
    {
        title: 'StartUps',
        slug: 'StartUps',
        description: 'Empower your startup journey by partnering with Invecent PVT LTD for mentorship, funding, and cutting-edge technological support.',
        link: 'https://www.google.com',
        image: [c],
    },
    {
        title: 'Investors',
        slug: 'Investors',
        description: 'Collaborate with Invecent PVT LTD to identify lucrative investment opportunities and drive impactful, sustainable growth.',
        link: 'https://www.google.com',
        image: [e],
    },
];

export default Collaboration;