import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import Bg from '../../../images/shapes/shape_title_under_line.svg';
import PageTitle from '../../pagetitle/PageTitle'
import shape3 from '../../../images/shapes/shape_space_1.svg';
import shape4 from '../../../images/shapes/shape_angle_1.webp';
import ITStaffing1 from '../../../images/about/ITStaffing1.webp' 
import ITStaffing2 from '../../../images/about/ITStaffing3.webp' 
import Header2 from '../../Header2/Header2';
import Footer from '../../footer/Footer';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';
import Scrollbar from '../../scrollbar/scrollbar';
import ContactSection from '../ContactSection';
import SEOWrapper from '../../../components/SEOWrapper/SEOWrapper';

const ITStaffing = () => {
    const [open, setAccordionOpen] = useState('1');

    const toggle = (id) => {
        if (open === id) {
            setAccordionOpen();
        } else {
            setAccordionOpen(id);
        }
    };

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    const metaData = {
        title: "IT Staffing & Recruitment Solutions | Invecent Private Limited",
        description: "Find top IT talent with Invecent's professional staffing solutions. We connect businesses with skilled technology professionals across software development, cloud computing, cybersecurity, and more.",
        keywords: "IT staffing, tech recruitment, technology jobs, IT recruitment, tech talent, software developers, cloud architects, cybersecurity experts, IT professionals, staffing solutions",
        additionalTags: {
            'og:type': 'website',
            'article:section': 'IT Staffing Services',
            'og:locale': 'en_US',
            'og:image': ITStaffing1
        }
    };

    return (
        <SEOWrapper metaData={metaData}>
            <Fragment>
                <Header2 />
                <main className="page_content service-single-page">
                    <PageTitle pageTitle={'IT Staffing'} pagesub={'Empowering Your Tech Team'} pageTop={'Services'} />
                    <section className="it_staffing_section pt-175 pb-80 bg-light section_decoration">
                        <div className="container">
                            <div className="heading_block text-center mb-5">
                                <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg})` }}>
                                    IT Staffing Solutions
                                </div>
                                <h2 className="heading_text mb-0">
                                    Building <mark>Elite Tech Teams</mark>
                                </h2>
                            </div>

                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6">
                                    <div className="content-block">
                                        <h1 className='staffing-title mb-4'>Expert IT Staffing Services</h1>
                                        <div className="content-description">
                                            <p className="lead mb-4">At Invecent, we provide comprehensive IT Staffing Services designed to connect you with top-tier technology talent, streamline your hiring process, and support your business objectives.</p>
                                            <p>Our expertise as an IT staffing consulting company ensures that you have access to the right professionals who can drive innovation, manage your IT infrastructure, and support your digital transformation efforts.</p>
                                        </div>
                                        <div className="mt-4">
                                            <Link onClick={ClickHandler} to="/contact" className="btn btn-primary me-3">Get Started</Link>
                                            <Link onClick={ClickHandler} to="/about" className="btn btn-outline-primary">Learn More</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="image-block position-relative">
                                        <img src={ITStaffing1} alt='IT Staffing Solutions' className="img-fluid rounded-lg shadow-lg" />
                                        <div className="shape-decorator-1 position-absolute">
                                            <img src={shape4} alt="Shape" className="floating-animation" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center justify-content-lg-between mt-5 pt-5">
                                <div className="col-lg-5">
                                    <div className="process-timeline">
                                        <Accordion open={open} toggle={toggle} className="accordion custom-accordion" id="service_process_faq">
                                            <ul className="timeline-steps">
                                                <li className={`timeline-step ${open === '1' ? 'active' : ''}`}>
                                                    <AccordionHeader targetId="1"><span>01. Discovery Phase</span></AccordionHeader>
                                                </li>
                                                <li className={`timeline-step ${open === '2' ? 'active' : ''}`}>
                                                    <AccordionHeader targetId="2"><span>02. Talent Matching</span></AccordionHeader>
                                                </li>
                                                <li className={`timeline-step ${open === '3' ? 'active' : ''}`}>
                                                    <AccordionHeader targetId="3"><span>03. Interview Process</span></AccordionHeader>
                                                </li>
                                                <li className={`timeline-step ${open === '4' ? 'active' : ''}`}>
                                                    <AccordionHeader targetId="4"><span>04. Onboarding</span></AccordionHeader>
                                                </li>
                                                <li className={`timeline-step ${open === '5' ? 'active' : ''}`}>
                                                    <AccordionHeader targetId="5"><span>05. Ongoing Support</span></AccordionHeader>
                                                </li>
                                            </ul>
                                        </Accordion>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="heading_block mb-4">
                                        <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg})` }}>
                                            Our Process
                                        </div>
                                        <h2 className="heading_text mb-0">
                                            How We <mark>Work</mark>
                                        </h2>
                                    </div>
                                    <Accordion open={open} toggle={toggle} className="accordion custom-accordion" id="service_process_faq">
                                        <AccordionItem className="accordion-item">
                                            <AccordionBody accordionId="1" className='accordion-content'>
                                                <h4>Understanding Your Needs</h4>
                                                <p>We begin by thoroughly understanding your technical requirements, company culture, and business objectives to ensure perfect alignment.</p>
                                            </AccordionBody>
                                        </AccordionItem>
                                        <AccordionItem className="accordion-item">
                                            <AccordionBody accordionId="2" className='accordion-content'>
                                                <h4>Candidate Selection</h4>
                                                <p>Our expert recruiters leverage our vast network to identify and screen candidates who match your specific technical and cultural requirements.</p>
                                            </AccordionBody>
                                        </AccordionItem>
                                        <AccordionItem className="accordion-item">
                                            <AccordionBody accordionId="3" className='accordion-content'>
                                                <h4>Streamlined Interviews</h4>
                                                <p>We coordinate and manage the entire interview process, ensuring efficient communication between all parties.</p>
                                            </AccordionBody>
                                        </AccordionItem>
                                        <AccordionItem className="accordion-item">
                                            <AccordionBody accordionId="4" className='accordion-content'>
                                                <h4>Smooth Transition</h4>
                                                <p>We facilitate a seamless onboarding process, handling all necessary documentation and ensuring a quick start.</p>
                                            </AccordionBody>
                                        </AccordionItem>
                                        <AccordionItem className="accordion-item">
                                            <AccordionBody accordionId="5" className='accordion-content'>
                                                <h4>Continuous Support</h4>
                                                <p>Our commitment extends beyond placement, providing ongoing support to ensure long-term success.</p>
                                            </AccordionBody>
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </div>

                            <div className="row align-items-center mt-5 pt-5">
                                <div className="col-lg-6">
                                    <div className="image-block position-relative">
                                        <img src={ITStaffing2} alt="IT Staffing Process" className="img-fluid rounded-lg shadow-lg" />
                                        <div className="shape-decorator-3 position-absolute">
                                            <img src={shape3} alt="Shape" className="floating-animation-reverse" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="content-block">
                                        <h3 className="section-title mb-4">Why Choose Our IT Staffing</h3>
                                        <p className="lead mb-4">We follow a rigorous process to ensure that we provide you with the best talent for your needs.</p>
                                        
                                        <div className="features-grid">
                                            <div className="feature-item">
                                                <div className="feature-icon">
                                                    <i className="fas fa-network-wired"></i>
                                                </div>
                                                <div className="feature-content">
                                                    <h4>Expertise & Network</h4>
                                                    <p>Access to our extensive network of pre-vetted IT professionals across various technologies and domains.</p>
                                                </div>
                                            </div>
                                            
                                            <div className="feature-item">
                                                <div className="feature-icon">
                                                    <i className="fas fa-puzzle-piece"></i>
                                                </div>
                                                <div className="feature-content">
                                                    <h4>Tailored Solutions</h4>
                                                    <p>Customized staffing solutions aligned with your specific business goals and technical requirements.</p>
                                                </div>
                                            </div>
                                            
                                            <div className="feature-item">
                                                <div className="feature-icon">
                                                    <i className="fas fa-bolt"></i>
                                                </div>
                                                <div className="feature-content">
                                                    <h4>Efficient Process</h4>
                                                    <p>Streamlined recruitment process ensuring quick placement of qualified candidates.</p>
                                                </div>
                                            </div>
                                            
                                            <div className="feature-item">
                                                <div className="feature-icon">
                                                    <i className="fas fa-shield-alt"></i>
                                                </div>
                                                <div className="feature-content">
                                                    <h4>Quality Assured</h4>
                                                    <p>Rigorous screening process ensuring only top-quality IT professionals join your team.</p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="cta-block mt-5">
                                            <p className="lead mb-4">Ready to build your dream tech team? Partner with Invecent today.</p>
                                            <Link onClick={ClickHandler} to="/contact" className="btn btn-primary">Get Started Now</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <ContactSection />
                <Footer />
                <Scrollbar />
            </Fragment>
        </SEOWrapper>
    );
};

export default ITStaffing;
