import WebDevMain from '../images/services/WebDevMain.webp'
import WebDevSub1 from '../images/services/WebDevSub1.webp'
import WebDevSub2 from '../images/services/WebDevSub2.webp'
import AppDevMain from '../images/services/AppDevMain.webp'
import AppDevSub1 from '../images/services/AppSub1.webp'
import AppDevSub2 from '../images/services/AppDevSub2.webp'
import EnterpriseMain from '../images/services/entripriseMain.webp'
import ErpSub1 from '../images/services/ErpSub1.webp'
import UIUXMain from '../images/services/UIUXMain.webp'
import UIUXSub1 from '../images/services/UIUXSub1.webp'
import UIUXSub2 from '../images/services/UIUXSub2.webp'
import DigitalMarketing from '../images/services/DigitalMarketing.webp'
import DigitalMarketingSub1  from '../images/services/seo.webp'
import DigitalMarketingSub2 from '../images/services/DigitalMarketingSub2.webp'
import DataSceinceGenAiMain from '../images/services/GenAiMain.webp'
import DataScienceSub1 from '../images/services/datascienceGenAI.webp'
import DataScienceSub2 from '../images/services/DataScienceSub1.webp'

import SoftwareManagmentSub1 from '../images/services/SoftwareManagmentSub1.webp'
// import SoftwareManagmentSub2 from '../images/services/SoftwareManagmentSub2.webp'
import NetworkManagmentMain from '../images/services/NetworkManagmentMain.png'
import NetworkManagmentSub1 from '../images/services/networkManagmentSub1.webp'
import NetworkManagmentSub2 from '../images/services/networkManagmentSub2.webp'
import amc from '../images/services/amc.png'
import amcsub1 from '../images/services/amcSub1.jpg'
import amcsub2 from '../images/services/amcsub2.png'
import amcsub3 from '../images/services/amcsub3.png'
import SoftwareTestingMain from '../images/services/SoftwareTestingMain.webp'
import SoftwareTestingSub2 from '../images/services/SoftwareTestingSub2.webp'
import SoftwareTestingSub1 from '../images/services/SoftwareTestingSub1.webp'
import CyberSecurity from '../images/services/CyberSecurity.webp'
import CyberSecuritySub1 from '../images/services/CyberSecuritySub1.webp'
import CyberSecuritySub2 from '../images/services/types-of-cyber-security-attack-1024x1024.webp'
import HRMSMain from '../images/services/HRMSMain.jpg'
import HRMSSub1 from '../images/services/HRMSSub1.jpg'
import HRMSSub2 from '../images/services/HRMSSub2.jpg'


import CloudMigrationMain from '../images/services/CloudMigrationMain.webp'
import CloudMigrationSub1 from '../images/services/CLoudMigrationSub1.webp'
import CloudMigrationSub2 from '../images/services/CLoudMigrationSub2.webp'
import ServersStorageMain from '../images/services/ServersMain.webp'
import ServersStorageSub1 from '../images/services/ServersStoragesSub1_1.webp'
import ServersStorageSub2 from '../images/services/ServersStoragesSub2.webp'
import DesktopSupportMain from '../images/services/DesktopSupportMain.webp'
import DesktopSupportSub1 from '../images/services/desktopsupportSub1.webp'
import DesktopSupportSub2 from '../images/services/desktopsupportSub2.webp'
import ITStaffingMain from '../images/services/ITstaffingMain.webp'
import ITStaffingSub1 from '../images/services/ITStaffingsub1.webp'
import ITStaffingSub2 from '../images/services/ITStaffingsub2.webp'
import DataAnalyticsMain from '../images/services/DataAnalyticsMain.webp'
import DataAnalyticsSub1 from '../images/services/DataAnalyticsSub1.webp'
import DataAnalyticsSub2 from '../images/services/DataAnalyticsSub2.webp'
import SystemIntegrationMain from '../images/services/SystemIntegrationMain.webp'
import SystemIntegrationSub1 from '../images/services/SystemIntegrationSub1.webp'
import SystemIntegrationSub2 from '../images/services/SystemIntegrationSub2.webp'


import servicebg from '../images/services/servicebg.webp'

import icon1 from '../images/icons/icon_code.svg'
import icon2 from '../images/icons/icon_programming_tree.svg'
import icon3 from '../images/icons/icon_monitor_2.svg'
import icon4 from '../images/icons/icon_phone.svg'
import icon5 from '../images/icons/icon_bug.svg'


const Services = [
    // Outsourcing Projects
    {
        Id: '1',
        sImg: WebDevMain,
        bgImg: servicebg,
        subImg1: WebDevSub1,
        subImg2: SoftwareManagmentSub1,
        title: 'Software Management',
        slug: 'Software-Management',
        thumb1: 'Strategy',
        thumb2: 'Consultation',
        col: 'col-lg-6',
        description: 'Streamline your development process, optimize software performance, and ensure effective resource management with our Software Management services. We help businesses maximize their software investments and drive continuous improvement.',
        features: ['Agile Methodology', 'Resource Optimization', 'Continuous Integration', 'Software Performance Monitoring', 'Custom Software Solutions'],
    },
    {
        Id: '2',
        sImg: NetworkManagmentMain,
        bgImg: servicebg,
        subImg1: NetworkManagmentSub1,
        subImg2: NetworkManagmentSub2,
        title: 'Network & Infrastructure Management',
        slug: 'Network-Infrastructure-Management',
        thumb1: 'Infrastructure',
        thumb2: 'Management',
        col: 'col-lg-6',
        description: 'Ensure high availability, security, and performance with our Network and Infrastructure Management services. Our team helps businesses build, manage, and optimize network infrastructure for seamless connectivity.',
        features: ['Network Optimization', 'Security Monitoring', 'Cloud Integration', 'Disaster Recovery Planning', 'Scalability Solutions'],
    },
    {
        Id: '3',
        sImg: CyberSecurity,
        bgImg: servicebg,
        subImg1: CyberSecuritySub1,
        subImg2: CyberSecuritySub2,
        title: 'CyberSecurity',
        slug: 'Cyber-Security',
        thumb1: 'Security',
        thumb2: 'Data Management',
        col: 'col-lg-6',
        description: 'Protect your business from evolving threats with our comprehensive CyberSecurity solutions. We offer server management, data storage, and protection services, ensuring the security, reliability, and scalability of your operations.',
        features: ['Advanced Threat Detection', 'Server Security Management', 'Data Encryption', 'Security Compliance', '24/7 Security Monitoring'],
    },
    {
        Id: '4',
        sImg: SoftwareTestingMain,
        bgImg: servicebg,
        subImg1: SoftwareTestingSub1,
        subImg2: SoftwareTestingSub2,
        title: 'Software Testing & Audit Functions',
        slug: 'Testing-Audit-Functions',
        thumb1: 'Audit',
        thumb2: 'SOftware Testing',
        col: 'col-lg-6',
        description: 'Ensure software quality and compliance with our Testing and Audit services. We provide thorough assessments to ensure your software is secure, functional, and meets industry standards.',
        features: ['Automated Testing', 'Compliance Audits', 'Performance Testing', 'Security Audits', 'Functional Testing'],
    },
    {
        Id: '5',
        sImg: amcsub1,
        bgImg: amc,
        subImg1: amcsub2,
        subImg2: amcsub3,
        title: 'Computer AMC Service',
        slug: 'Computer-AMC-Service',
        thumb1: 'Computer Management',
        thumb2: 'AMC Service',
        col: 'col-lg-6',
        description: 'Ensure your computer systems are always up-to-date and running smoothly with our Computer AMC (Annual Maintenance Contract) services. Our team provides comprehensive support, maintenance, and repair services to minimize downtime and optimize system performance.',
        features: ['System Maintenance', 'Repair Services', 'Software Updates', 'Hardware Upgrades', 'Technical Support'],
    },
    {
        Id: '6',
        sImg: HRMSMain,
        bgImg: servicebg,
        subImg1: HRMSSub1,
        subImg2: HRMSSub2,
        title: 'HRMS Assessments',
        slug: 'HRMS-Assessments',
        thumb1: 'HR Management',
        thumb2: 'Assessment',
        col: 'col-lg-6',
        description: 'Optimize your Human Resource Management Systems (HRMS) to align with your business goals. Our assessments help improve HR processes, increase efficiency, and ensure smooth integration with your enterprise systems.',
        features: ['HR Process Optimization', 'Employee Performance Evaluation', 'Automation Solutions', 'Data-Driven Insights', 'Scalability & Flexibility'],
    },

    // Product Development
    {
        Id: '7',
        sImg: WebDevMain,
        sicon: icon3,
        bgImg: servicebg,
        subImg1: WebDevSub1,
        subImg2: WebDevSub2,
        title: 'Web Development',
        slug: 'Web-Application-Development',
        thumb1: 'Web Dev',
        thumb2: 'Frontend',
        col: 'col-lg-4',
        features: ['Full-Stack Web Development', 'Responsive Web Design', 'E-Commerce Solutions', 'API Integration', 'Web Portal Development'],
        description: 'Create robust, scalable, and secure web applications with our full-stack web development services. We deliver custom solutions that enhance user experience and meet your business needs.',
    },
    {
        Id: '8',
        sImg: AppDevMain,
        sicon: icon1,
        bgImg: servicebg,
        subImg1: AppDevSub1,
        subImg2: AppDevSub2,
        title: 'Mobile Development',
        slug: 'Mobile-Application-Development',
        thumb1: 'Mobile App',
        thumb2: 'Development',
        col: 'col-lg-4',
        features: ['Native App Development', 'Cross-Platform Development', 'UI/UX Design', 'Mobile App Maintenance', 'Enterprise Mobile Solutions'],
        description: 'Develop innovative and user-friendly mobile applications for iOS and Android platforms. Our mobile development services create high-performing apps tailored to your business objectives.',
    },
    {
        Id: '9',
        sImg: EnterpriseMain,
        sicon: icon2,
        bgImg: servicebg,
        subImg1: ErpSub1,
        subImg2: WebDevSub2,
        title: 'Enterprise Application',
        slug: 'Enterprise-Application',
        thumb1: 'Business Solutions',
        thumb2: 'Enterprise',
        col: 'col-lg-3',
        features: ['ERP Solutions', 'System Integration', 'Data Migration', 'Custom Business Applications', 'Cloud-based Enterprise Solutions'],
        description: 'Develop and integrate scalable enterprise applications that support your business growth. Our enterprise solutions optimize operations and integrate seamlessly with your existing infrastructure.',
    },
    {
        Id: '10',
        sImg: UIUXMain,
        sicon: icon4,
        bgImg: servicebg,
        subImg1: UIUXSub1,
        subImg2: UIUXSub2,
        title: 'UI & UX Designing',
        slug: 'UI-UX-Designing',
        thumb1: 'UI Design',
        thumb2: 'UX Design',
        col: 'col-lg-3',        
        features: ['User-Centered Design', 'Mobile-First Approach', 'Wireframing and Prototyping', 'Interactive UI Elements', 'Usability Testing'],
        description: 'Enhance user engagement and interface design with our expert UI/UX design services. We create intuitive and visually appealing user experiences for both web and mobile applications.',
    },
    {
        Id: '11',
        sImg: DigitalMarketing,
        sicon: icon3,
        bgImg: servicebg,
        subImg1: DigitalMarketingSub1,
        subImg2: DigitalMarketingSub2,
        title: 'Digital Marketing',
        slug: 'Digital-Marketing',
        thumb1: 'Digital Marketing',
        thumb2: 'Digital Marketing',
        col: 'col-lg-3',        
        features: ['SEO Optimization', 'PPC Advertising', 'Social Media Marketing', 'Content Marketing', 'Email Campaigns'],
        description: 'Boost your online presence and drive targeted traffic with our comprehensive Digital Marketing services. From SEO to social media, we help businesses succeed in the digital space.',
    },
    {
        Id: '12',
        sImg: DataSceinceGenAiMain,
        sicon: icon5,
        bgImg: servicebg,
        subImg1: DataScienceSub1,
        subImg2: DataScienceSub2,
        title: 'DataScience & GenAI',
        slug: 'DataScience-GenAI',
        thumb1: 'DataScience',
        thumb2: 'Artificial Intelligence',
        col: 'col-lg-3',
        features: ['Machine Learning', 'Data Analytics', 'AI Solutions', 'Predictive Modeling', 'Data Integration'],
        description: 'Leverage the power of Data Science and GenAI to drive smarter business decisions. We offer AI-driven solutions to optimize ERP, data management, and business processes.',
    },

    // Managed Services
    {
        Id: '13',
        sImg: ITStaffingMain,
        bgImg: servicebg,
        subImg1: ITStaffingSub1,
        subImg2: ITStaffingSub2,
        title: 'IT Staffing',
        slug: 'IT-Staffing',
        thumb1: 'IT Professionals',
        thumb2: 'Recruitment',
        col: 'col-lg-6',
        description: 'Find top-tier IT professionals to meet your business needs with our IT Staffing services. We connect you with skilled talent to support your digital transformation.',
        features: ['Talent Sourcing', 'IT Recruitment', 'Flexible Staffing Solutions', 'Contract-to-Hire Services', 'On-Demand Talent'],
    },
    {
        Id: '14',
        sImg: CloudMigrationMain,
        bgImg: servicebg,
        subImg1: CloudMigrationSub1,
        subImg2: CloudMigrationSub2,
        title: 'Migration to Cloud',
        slug: 'Migration-to-Cloud',
        thumb1: 'Cloud',
        thumb2: 'Migration',
        col: 'col-lg-6',
        description: 'Migrate your infrastructure to the cloud with minimal disruption, maximizing scalability and flexibility. Our cloud migration services ensure a smooth transition for your business.',
        features: ['Cloud Strategy', 'Cloud Migration Planning', 'Data Migration', 'Cloud Infrastructure Optimization', 'Post-Migration Support'],
    },
    {
        Id: '15',
        sImg: ServersStorageMain,
        bgImg: servicebg,
        subImg1: ServersStorageSub1,
        subImg2: ServersStorageSub2,
        title: 'Servers & Storages',
        slug: 'Servers-Storages',
        thumb1: 'Servers',
        thumb2: 'Maintenance',
        col: 'col-lg-6',
        description: 'Maximize your server and storage infrastructure with our expert maintenance and support services. Our team ensures your systems are always running at peak performance.',
        features: ['Server Maintenance', 'Storage Optimization', 'System Monitoring', 'Security Audits', 'Performance Tuning'],
    },
    {
        Id: '16',
        sImg: DesktopSupportMain,
        bgImg: servicebg,
        subImg1: DesktopSupportSub1,
        subImg2: DesktopSupportSub2,
        title: 'Desktop Support',
        slug: 'Desktop-Support',
        thumb1: 'Helpdesk',
        thumb2: 'Support',
        col: 'col-lg-6',
        description: 'Our service desk support ensures your IT infrastructure is always up and running, providing fast and efficient issue resolution for your business operations.',
        features: ['IT Support Desk', 'Issue Resolution', 'Incident Management', 'User Access Support', 'Remote Assistance'],
    },
    {
        Id: '17',
        sImg: DataAnalyticsMain,
        bgImg: servicebg,
        subImg1: DataAnalyticsSub1,
        subImg2: DataAnalyticsSub2,
        title: 'Data Analytics',
        slug: 'Data-Analytics',
        thumb1: 'Analytics',
        thumb2: 'Big Data',
        col: 'col-lg-6',
        description: 'Leverage data analytics to drive business insights and make data-driven decisions. Our data analytics services help you gain a competitive edge and optimize business performance.',
        features: ['Data Visualization', 'Predictive Analytics', 'Big Data Solutions', 'Business Intelligence', 'Custom Reporting'],
    },
    {
        Id: '18',
        sImg: SystemIntegrationMain,
        bgImg: servicebg,
        subImg1: SystemIntegrationSub1,
        subImg2: SystemIntegrationSub2,
        title: 'System Integration',
        slug: 'System-Integration',
        thumb1: 'Integration',
        thumb2: 'Technology',
        col: 'col-lg-6',
        description: 'Integrate diverse systems and technologies to create a cohesive IT environment. Our system integration services help you connect applications, data, and devices for seamless operations.',
        features: ['API Integration', 'Cloud Integration', 'Legacy System Integration', 'Data Synchronization', 'Technology Compatibility'],
    }
];


export default Services;