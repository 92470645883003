import React, { useState, useEffect } from 'react';
import Services from '../../../api/service';
import { Link } from "react-router-dom";
import Bg from '../../../images/shapes/shape_title_under_line.svg';
import shape1 from '../../../images/shapes/shape_line_5.svg';
import shape2 from '../../../images/shapes/shape_line_6.svg';
import shape3 from '../../../images/shapes/shape_space_1.svg';
import shape4 from '../../../images/shapes/shape_angle_1.webp';
import shape5 from '../../../images/shapes/shape_angle_2.webp';

const ServiceSection = (props) => {
    const [visibleServices, setVisibleServices] = useState([]);

    useEffect(() => {
        // Setup IntersectionObserver to detect when service blocks are visible
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Add service index to visibleServices when it comes into view
                    const serviceIndex = entry.target.getAttribute('data-index');
                    setVisibleServices(prevState => [
                        ...prevState,
                        serviceIndex
                    ]);
                    observer.unobserve(entry.target); // Stop observing once it is visible
                }
            });
        }, {
            threshold: 0.5 // Trigger when 50% of the block is visible
        });

        // Start observing each service block
        const serviceBlocks = document.querySelectorAll('.service_block_2');
        serviceBlocks.forEach(block => observer.observe(block));

        // Cleanup observer on component unmount
        return () => observer.disconnect();
    }, []);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <section className="service_section pt-175 pb-80 bg-light section_decoration xb-hidden">
            <div className="container">
                <div className="heading_block text-center">
                    <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg})` }}>
                    <strong> We assist you in </strong>
                    </div>
                    <h1 className="heading_text mb-0">
                    Empowering
                    <mark> Innovation with Seamless </mark>
                    End-to-End IT Integration Solutions
                    </h1>
                </div>

                <div className="row">
                {Services.slice(6, 12).map((service, srv) => (
            <div className="col-lg-4" key={srv}>
            <div
                className={`service_block_2 zoomInUpBlock ${visibleServices.includes(srv.toString()) ? 'visible' : ''}`}
                data-index={srv} // This is used to apply custom hover styles based on the index
            >
                <div className="service_icon">
                    <img src={service.sicon} alt="Invecent - Service icon" />
                </div>
                <h3 className="service_title">
                    <Link onClick={ClickHandler} to={`/service-single/${service.slug}`}>
                        {service.title}
                    </Link>
                </h3>
                <ul className="icon_list unordered_list_block">
                    {service.features.map((feature, featureitem) => (
                        <li key={featureitem}>
                            <span className="icon_list_icon">
                                <i className="fa-regular fa-circle-dot"></i>
                            </span>
                            <span className="icon_list_text">
                                {feature}
                            </span>
                        </li>
                    ))}
                </ul>
                        </div>
                    </div>
                ))}
            </div>
            </div>

            {/* Background Shapes */}
            <div className="decoration_item shape_image_1">
                <img src={shape1} alt="Invecent Shape"/>
            </div>
            <div className="decoration_item shape_image_2">
                <img src={shape2} alt="Invecent Shape"/>
            </div>
            <div className="decoration_item shape_image_3">
                <img src={shape3} alt="Invecent Shape"/>
            </div>
            <div className="decoration_item shape_image_4">
                <img src={shape4} alt="Invecent Shape Angle"/>
            </div>
            <div className="decoration_item shape_image_5">
                <img src={shape5} alt="Invecent Shape Angle"/>
            </div>
        </section>
    );
};

export default ServiceSection;