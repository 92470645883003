import React, { Fragment } from 'react';
import Header2 from '../../components/Header2/Header2';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import ContactSection from '../../components/ContactSection';
import SEOWrapper from '../../components/SEOWrapper/SEOWrapper';

const ContactPage = () => {
    const metaData = {
        title: "Contact Us | Invecent Private Limited",
        description: "Get in touch with Invecent Private Limited. Contact our team for inquiries about our IT services, partnership opportunities, or career options. We're here to help!",
        keywords: "contact Invecent, IT company contact, software company contact, reach out, business inquiry, IT services contact, technical support",
        additionalTags: {
            'og:type': 'website',
            'article:section': 'Contact',
            'og:locale': 'en_US'
        }
    };

    return (
        <SEOWrapper metaData={metaData}>
            <Fragment>
                <Header2 />
                <main className="page_content about-page">
                    <PageTitle pageTitle={'Contact Us'} pagesub={'Us'} pageTop={'Contact'} />
                    <ContactSection />
                </main>
                <Footer />
                <Scrollbar />
            </Fragment>
        </SEOWrapper>
    )
};

export default ContactPage;