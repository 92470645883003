import React from 'react';
import sIcon1 from '../../images/icons/icon_clock.svg'
import sIcon2 from '../../images/icons/icon_dart_board_2.svg'
import sIcon3 from '../../images/icons/icon_target.svg'

const Policy = [
    {
        title: 'Our History',
        subTitle: 'Since 2018, we have been a forward-thinking and dependable software engineering partner for top-tier brands, known as co.',
        icon: sIcon1,
    },
    {
        title: 'Our Mission',
        subTitle: 'A mission statement outlines the company\'s objectives, target audience, and the reasons behind their support. Conversely',
        icon: sIcon2,
    },
    {
        title: 'Our Vision',
        subTitle: 'A company or organization\'s vision statement concentrates on the future potential; it\'s about what they aim to become and how they',
        icon: sIcon3,
    },


]


const PolicySection = (props) => {

    return (
        <section className="policy_section bg-light">
            <div className="container">
                <div className="row">
                    {Policy.map((policy, pitem) => (
                        <div className="col-lg-4" key={pitem}>
                            <div className="iconbox_block">
                                <div className="iconbox_icon">
                                    <img src={policy.icon} alt="Dollar SVG Icon" />
                                </div>
                                <div className="iconbox_content">
                                    <h3 className="iconbox_title">{policy.title}</h3>
                                    <p className="mb-0">
                                        {policy.subTitle}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default PolicySection;