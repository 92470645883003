import React from 'react';
import Bg2 from '../../../images/shapes/shape_bg_1.webp'
import Bg3 from '../../../images/shapes/shape_title_under_line.svg'
import aIcon4 from '../../../images/icons/icon_global.svg'
import aIcon5 from '../../../images/shapes/shape_line.webp'
import shape1 from '../../../images/shapes/shape_space_2.svg'
import  ArifEffect  from '../../../main-component/Animations/ArifEffect'


const About = (props) => {

  const customVariants1 = {
    hidden: { opacity: 0, x: -100 },
    peek: { opacity: 1, x: 0 },
  };
  const customVariants2 = {
    hidden: { opacity: 0, x: 100 },
    peek: { opacity: 1, x: 0 },
  };

  return (
    <section className="about_and_case_section section_space section_decoration" style={{ backgroundColor: '#343a40ed' }}>
      <div className="container">
        <div className="row align-items-center justify-content-lg-between">
          <div className="col-lg-6">
          <ArifEffect variants={customVariants1}  transition = {{duration: 1, delay: 0.69 }}>
            <div className="about_content" style={{color: '#64b5f6'}}>
              <div className="heading_block mb-0 text-white">
                <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg3})`,color: 'blue' }}>
                  <strong>About Us</strong>
                </div>
                <h2 className="heading_text" style={{color: '#64b5f6'}}>
                  Invecent brings Innovative ideas
                </h2>
                <p className="heading_description mb-0">
                  At Invecent, our mission is to empower businesses through innovative software solutions that streamline operations, foster growth, and drive success. With a commitment to excellence and customer satisfaction we strive.
                </p>
              </div>
            </div>
          </ArifEffect>
          </div>
          <div className="col-lg-5 order-lg-last">
          <ArifEffect variants={customVariants2}  transition = {{duration: 1, delay: 0.69 }}>
            <div className="about_image_2">
              {/* <div className="image_wrap">
                <img src={about1} alt="Invecent - About" />
              </div> */}
              <div className="about_funfact_info" style={{ backgroundImage: `url(${Bg2})` }}>
                <div className="customer_count">
                  <ul className="unordered_list">
                    {/* <li>
                      <img src={aIcon1} alt="Customer Avatar" />
                    </li>
                    <li>
                      <img src={aIcon2} alt="Customer Avatar" />
                    </li> */}
                    {/* <li>
                      <img src={aIcon3} alt="Customer Avatar" />
                    </li> */}
                    <li>
                      <span>6k+</span>
                    </li>
                  </ul>
                  <p className="mb-0">
                    <strong style={{fontSize: '150%'}}>Happy Customers</strong> 
                  </p>
                </div>
                <div className="about_funfact_counter">
                  <div className="funfact_item">
                    <div className="counter_value">
                      <span className="odometer" data-count="6">110</span>
                      <span>+</span>
                    </div>
                    <h3 className="funfact_title mb-0"><strong style={{fontSize: '110%'}}>Projects Done</strong> 
                    </h3>
                  </div>
                  <div className="funfact_item">
                    <div className="counter_value">
                      <span className="odometer" data-count="100">100</span>
                      <span>%</span>
                    </div>
                    <h3 className="funfact_title mb-0"><strong style={{fontSize: '110%'}}>Results Guaranteed</strong> 
                    </h3>
                  </div>
                </div>
                <a className="btn btn-primary" href="about.html">
                  <span className="btn_label" data-text="Learn More">Learn More</span>
                  <span className="btn_icon">
                    <i className="fa-solid fa-arrow-up-right"></i>
                  </span>
                </a>
                <div className="icon_globe">
                  <img src={aIcon4} alt="Icon Globe" />
                </div>
              </div>
              <div className="space_line">
                <img src={aIcon5} alt="Shape Line" />
              </div>
            </div>
          </ArifEffect>
          </div>
        </div>

      </div>
      <div className="decoration_item shape_image_1">
        <img src={shape1} alt="Invecent Shape" />
      </div>
    </section>
  )
}

export default About;