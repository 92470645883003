import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom'
import blogs from '../../api/blogs'
import Header2 from '../../components/Header2/Header2';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import BlogSingle from '../../components/BlogDetails/BlogDetails';
import SEOWrapper from '../../components/SEOWrapper/SEOWrapper';

const BlogDetails = () => {
    const { slug } = useParams()
    const BlogDetailsData = blogs.find(item => item.slug === slug) || {
        title: 'Blog Post',
        description: 'Blog post details',
        date: new Date().toISOString()
    };

    const metaData = {
        title: `${BlogDetailsData.title} | Invecent Private Limited Blog`,
        description: `${BlogDetailsData.description ? BlogDetailsData.description.slice(0, 150) : BlogDetailsData.title}...`,
        keywords: `${BlogDetailsData.title.toLowerCase()}, tech blog, IT insights, digital transformation blog, technology trends, industry insights, tech news, software development blog`,
        additionalTags: {
            'og:type': 'article',
            'article:section': 'Blog',
            'article:published_time': BlogDetailsData.date,
            'og:locale': 'en_US'
        }
    };

    return (
        <SEOWrapper metaData={metaData}>
            <Fragment>
                <Header2 />
                <main className="page_content about-page">
                    <PageTitle pageTitle={BlogDetailsData.title} pagesub={'Details'} pageTop={'Blog'}/>
                    <BlogSingle/>
                </main>
                <Footer />
                <Scrollbar />
            </Fragment>
        </SEOWrapper>
    )
};
export default BlogDetails;