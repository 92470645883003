import React, { Fragment, useEffect, useState } from 'react';
import SEOWrapper from '../../components/SEOWrapper/SEOWrapper';
import Header2 from '../../components/Header2/Header2';
import Hero2 from '../../components/hero2/hero2';
import FeaturePartners from '../../components/software-company-components/FeaturePartners';
import ServiceSection from '../../components/software-company-components/ServiceSection/ServiceSection';
import About from '../../components/software-company-components/about/about';
import ProcessTechnology from '../../components/software-company-components/ProcessTechnology/ProcessTechnology';
import FaqSection from '../../components/software-company-components/FaqSection/FaqSection';
import Scrollbar from '../../components/scrollbar/scrollbar';
import BlogSection from '../../components/software-company-components/BlogSection/BlogSection';
import ContactSection from '../../components/software-company-components/ContactSection';
import Footer from '../../components/footer/Footer';
import PolicySection from '../../components/PolicySection/PolicySection';
import Casestudy from '../../components/software-company-components/casestudy/casestudy.js';
import ChatBot from "react-chatbotify";

const HomePage2 = () => {
    const [isVisible, setIsVisible] = useState(false); // State to track visibility

    const metaData = {
        title: "Invecent Private Limited - Home | Software Solutions & Services",
        description: "Welcome to Invecent Private Limited. We provide cutting-edge software solutions and IT services for businesses. Explore our innovative technology solutions.",
        keywords: "software company, IT services, digital solutions, technology consulting, software development, Invecent, business solutions",
        additionalTags: {
            'og:type': 'website',
            'og:locale': 'en_US'
        }
    };

    const flow = {
        "start": {
          "message": "Hello! Welcome to Invecent Private Limited. How can I assist you today?",
        }
      };
      

    useEffect(() => {
        // Create an IntersectionObserver instance
        const observer = new IntersectionObserver(
            (entries) => {
                // Check if the element is visible in the viewport
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsVisible(true); // Set visibility to true when the element is in the viewport
                        observer.unobserve(entry.target); // Stop observing once it's visible
                    }
                });
            },
            { threshold: 0.5 } // Trigger when 50% of the element is visible
        );

        // Observe the element
        const element = document.querySelector('.diract_contact_links');
        if (element) observer.observe(element);

        // Cleanup observer when component unmounts
        return () => {
            if (element) observer.unobserve(element);
        };
    }, []);

    return (
        <SEOWrapper metaData={metaData}>
            <Fragment>
                <div>
                    <Header2 />
                    <main className="page_content">
                        <Hero2 />
                        <FeaturePartners />
                        <PolicySection />
                        <ServiceSection />
                        <About />
                        <ProcessTechnology />
                        <ContactSection />
                        <Casestudy />
                        <FaqSection />
                        <BlogSection />
                    </main>
                    <div className="container" style={{ marginTop: '4%', marginBottom: '4%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <div className={`diract_contact_links ${isVisible ? 'fade-in' : ''}`}>
                            <div className="row justify-content-center">
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div className="iconbox_block our_location" style={{ margin: '10px' }}>
                                        <img className="location_image" src="https://images.pexels.com/photos/1603650/pexels-photo-1603650.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="India Office" style={{ width: '100%', height: 'auto' }} />
                                        <h4>India Office</h4>
                                        <p><strong>Hitech City, Hyderabad</strong></p>
                                        <p><i className="fa fa-phone" aria-hidden="true"><a href="tel:+918557529947"> +91 855-752-9947</a></i></p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div className="iconbox_block our_location" style={{ margin: '10px' }}>
                                        <img className="location_image" src="https://imgs.search.brave.com/G-pOVc6Ule1Y-OdxvOXv3CiA_WirZPJdHIyCfpzJT5g/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzA3LzgwLzg5LzI3/LzM2MF9GXzc4MDg5/Mjc4M19BSlREUENC/SURUbVNITWtJSjNV/SGdGSDNTV2FsNVlt/Zy5qcGc" alt="Portugal office" style={{ width: '100%', height: 'auto' }} />
                                        <h4>Portugal Office</h4>
                                        <p><strong>Ribeira, Portugal</strong></p>
                                        <p><i className="fa fa-phone" aria-hidden="true"><a href="tel:+918557529947"> +91 855-752-9947</a></i></p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="iconbox_block our_location" style={{ margin: '10px' }}>
                                        <img className="location_image" src="https://images.pexels.com/photos/51363/london-tower-bridge-bridge-monument-51363.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="UK Office" style={{ width: '100%', height: 'auto' }} />
                                        <h4>UK Office</h4>
                                        <p><strong>Luton, UK</strong></p>
                                        <p><i className="fa fa-phone" aria-hidden="true"><a href="tel:+918557529947"> +91 855-752-9947</a></i></p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="iconbox_block our_location" style={{ margin: '10px' }}>
                                        <img className="location_image" src="https://images.pexels.com/photos/3214995/pexels-photo-3214995.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="UAE Office" style={{ width: '100%', height: '23vh' }} />
                                        <h4>UAE Office</h4>
                                        <p><strong>AL Mankhool, UAE</strong></p>
                                        <p><i className="fa fa-phone" aria-hidden="true"><a href="tel:+918557529947"> +91 855-752-9947</a></i></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <Scrollbar />
                    <ChatBot flow={flow} />
                </div>
            </Fragment>
        </SEOWrapper>
    )
};
export default HomePage2;