import Ecom from '../images/portfolio/ECommerce.webp'
import elearning from '../images/portfolio/elearning.webp'
import Medical from '../images/portfolio/medico-tech.webp'


import tImg1 from '../images/case/icon_angular.svg'
import tImg2 from '../images/case/icon_elephent.svg'
import tImg3 from '../images/case/icon_netcore.svg'


const Project = [
    {
        Id: '1',
        pImg: Medical,
        title: 'Healthcare and Medical Technology',
        slug: 'Healthcare and Medical Technology',
        sub: 'Healthcare',
        Industry: 'Wellness & Fitness',
        Country: 'Canada, Alberta',
        Technologies1: tImg1,
        Technologies2: tImg3,
        description: 'Revolutionizing healthcare with cutting-edge digital solutions for improved patient outcomes and operational efficiency. Delivering comprehensive hospital management systems, and wearable health tech integrations.',
    },
    {
        Id: '2',
        pImg: Ecom,
        title: 'E-Commerce and Retail',
        slug: 'E-Commerce and Retail',
        sub: 'Computer Software',
        Industry: 'Computer software',
        Country: 'Germany, Issum',
        Technologies1: tImg1,
        Technologies2: tImg2,        description: 'We develop feature-rich e-commerce platforms for enhanced user experiences, sales, and inventory management. Our omnichannel retail solutions ensure measurable growth for businesses, fostering customer connections and loyalty.',
    },
    {
        Id: '3',
        pImg: elearning,
        title: 'Education and E-Learning',
        slug: 'Education and E-Learning',
        sub: 'Web Design',
        Industry: 'Computer software',
        Country: 'Germany, Issum',
        Technologies1: tImg3,
        Technologies2: tImg2,
        description: 'Transformative e-learning platforms for accessible, engaging, and interactive education. Virtual classrooms, adaptive learning, and analytics for personalized learning environments. Shaping the future of education through technology.',
    },
    {
        Id: '4',
        pImg: Ecom,
        title: 'Mobile App Design',
        slug: 'Mobile-App-Design',
        sub: 'App Design',
        description: 'Our all-encompassing IELTS Coaching curriculum encompasses every aspect...',
    }

]

export default Project;