import React, { Fragment } from 'react';
import Header2 from '../../components/Header2/Header2';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import ArifEffect from '../Animations/ArifEffect';
import SEOWrapper from '../../components/SEOWrapper/SEOWrapper';

// Sample CareerPage Component
const CareerPage = () => {
    const metaData = {
        title: "Careers | Invecent Private Limited",
        description: "Join our dynamic team at Invecent Private Limited. Explore exciting career opportunities in software development, IT consulting, and digital innovation. Build your future with us!",
        keywords: "Invecent careers, IT jobs, software developer jobs, technology careers, job opportunities, tech jobs, IT careers, employment",
        additionalTags: {
            'og:type': 'website',
            'article:section': 'Careers',
            'og:locale': 'en_US'
        }
    };

    return (
        <SEOWrapper metaData={metaData}>
            <Fragment>
                <Header2 />
                <main className="page_content about-page">
                    <PageTitle pageTitle={'Careers'} pagesub={'Us'} pageTop={'Join'} />
                    <div className="career-page">

                        {/* First Section: About Career Page */}
                        <section className="career-intro text-center">
                            <div className="container">
                                <ArifEffect>
                                    <h2 className="section-title">Welcome to Our Career Page</h2>
                                    <p className="section-description">
                                        Join our team and take your career to the next level. We offer exciting opportunities across various fields, from IT to non-IT roles, in a collaborative and growth-oriented environment.
                                    </p>
                                </ArifEffect>
                            </div>
                        </section>

                        {/* Second Section: Why Join Us */}
                        <section className="why-join-us">
                            <div className="container">
                                <h2 className="section-title text-center">Why Join Us</h2>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="why-card">
                                            <h3>Growth Opportunities</h3>
                                            <p>We believe in fostering growth and development in all our employees through continuous learning and mentorship.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="why-card">
                                            <h3>Inclusive Culture</h3>
                                            <p>Our work culture promotes diversity and inclusion, ensuring every voice is heard and valued.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="why-card">
                                            <h3>Competitive Salary</h3>
                                            <p>We offer attractive compensation packages that reward hard work and dedication.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Third Section: Job Categories */}
                        <section className="job-categories text-center">
                            <div className="container">
                                <h2 className="section-title">Explore Career Opportunities</h2>
                                <div className="row">
                                    {/* IT Jobs */}
                                    <div className="col-md-4">
                                        <ArifEffect>
                                            <div className="job-card">
                                                <h3>IT Jobs</h3>
                                                <p>Looking for IT professionals to join our dynamic team. Explore roles in development, infrastructure, and more.</p>
                                                <a href="https://www.invecent.com/ITForm" target="_blank" rel="noreferrer"><button className="btn btn-primary">View IT Jobs</button></a>
                                            </div>
                                        </ArifEffect>
                                    </div>

                                    {/* Non-IT Jobs */}
                                    <div className="col-md-4">
                                        <ArifEffect>
                                            <div className="job-card">
                                                <h3>Non-IT Jobs</h3>
                                                <p>We also offer career opportunities in marketing, HR, sales, and other business functions.</p>
                                                <a href="https://www.invecent.com/NonITForm" target="_blank" rel="noreferrer"><button className="btn btn-primary">View Non-IT Jobs</button></a>
                                            </div>
                                        </ArifEffect>

                                    </div>

                                    {/* Internships */}
                                    <div className="col-md-4">
                                        <ArifEffect>
                                            <div className="job-card">
                                                <h3>Internships</h3>
                                                <p>Start your career with hands-on experience in various fields. Explore internship opportunities now.</p>
                                                <a href="https://www.invecent.com/internship" target="_blank" rel="noreferrer"><button className="btn btn-primary">View Internships</button></a>
                                            </div>
                                        </ArifEffect>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Fourth Section: Hiring Process */}
                        <section className="hiring-process">
                            <div className="container">
                                <h2 className="section-title text-center">Our Hiring Process</h2>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="process-step">
                                            <h4>Step 1: Application</h4>
                                            <p>Submit your application online through our career portal or via email.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="process-step">
                                            <h4>Step 2: Screening</h4>
                                            <p>Our HR team reviews your application and shortlists candidates for the next round.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="process-step">
                                            <h4>Step 3: Interview</h4>
                                            <p>You'll be invited for a face-to-face or virtual interview to assess your skills and fit.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="process-step">
                                            <h4>Step 4: Offer</h4>
                                            <p>We extend an offer to successful candidates and guide them through the onboarding process.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
                <Footer />
                <Scrollbar />
            </Fragment>
        </SEOWrapper>
    );
};

export default CareerPage;
