import React, { Fragment, useState } from 'react';
import SEOWrapper from '../../components/SEOWrapper/SEOWrapper';
import Services from '../../api/service'
import ModalVideo from 'react-modal-video'
import Header2 from '../../components/Header2/Header2';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import icon from '../../images/icons/icon_check_3.svg'
import ContactSection from '../../components/software-company-components/ContactSection';

const CSRPage = () => {
    const metaData = {
        title: "Corporate Social Responsibility | Invecent Private Limited",
        description: "Learn about Invecent's commitment to corporate social responsibility. Discover our initiatives in sustainability, community development, education, and environmental conservation.",
        keywords: "CSR, corporate social responsibility, sustainability, community development, social impact, environmental initiatives, corporate citizenship, social responsibility",
        additionalTags: {
            'og:type': 'website',
            'article:section': 'CSR',
            'og:locale': 'en_US'
        }
    };

    const ServiceDetails = Services.find(item => item)

    const [isOpen, setOpen] = useState(false)

    return (
        <SEOWrapper metaData={metaData}>
            <Fragment>
                <Header2 />
                <main className="page_content service-single-page">
                    <PageTitle pageTitle={'CSR'} pagesub={'Details'} pageTop={'CSR'} pageBg={ServiceDetails.bgImg}/>
                    <section className="service_details_section section_space bg-light">
                        <div className="container">
                            <div className='row'>
                                <div className="col-lg-6 details_item_image position-relative slideInLeft fa-flip">
                                <img src={ServiceDetails.sImg} alt="Service Details"/>
                                    <button className="video_btn ripple_effect" onClick={() => setOpen(true)} hidden>
                                        <span className="btn_icon">
                                            <i className="fa-solid fa-play"></i>
                                        </span>
                                    </button>
                            </div>
                            <div className='col-lg-6 slideInRight'>
                                <h2 className="details_item_title">CSR</h2>
                            <p>
                            Our Corporate Social Responsibility (CSR) initiatives are designed to positively impact the communities we serve. We believe that our success is intertwined with the well-being of society and the environment. Through our CSR efforts, we strive to make a meaningful difference in the lives of people, promote environmental sustainability, and contribute to the economic development of the regions we operate in.
                            </p>
                            <p>
                            At our IT solution agency, we specialize in crafting robust and reliable network architectures tailored to meet the unique needs of our clients. From small businesses to large enterprises, we understand the critical importance of a well-designed network infrastructure in driving efficiency, security, and scalability.
                            </p>
                            </div>
                            
                            {/*                        
                            <p className="mb-4 zoomInUp">
                                Here are six key points that can be associated with a digital Transformation gallery case global Digital Systems Engineer Services leader helping Fortune 500 companies on their innovation agenda:
                            </p> */}
                            {/* <div className="row mb-4">
                                <div className="col-lg-6">
                                    <ul className="icon_list unordered_list_block">
                                        {ServiceDetails.features.slice(0,3).map((feature, featureitem) => (
                                        <li key={featureitem}>
                                            <span className="icon_list_icon">
                                                <img src={icon} alt="Check SVG Icon"/>
                                            </span>
                                            <span className="icon_list_text">
                                                {feature}
                                            </span>
                                        </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="col-lg-6 zoomInUp">
                                    <ul className="icon_list unordered_list_block">
                                    {ServiceDetails.features.slice(3,6).map((feature, featureitem) => (
                                        <li key={featureitem}>
                                            <span className="icon_list_icon">
                                                <img src={icon} alt="Check SVG Icon"/>
                                            </span>
                                            <span className="icon_list_text">
                                                {feature}
                                            </span>
                                        </li>
                                        ))}
                                    </ul>
                                </div>
                            </div> */}
                            {/* <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="details_item_image m-0">
                                        <img src={ServiceDetails.subImg1} alt="Service Details"/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="details_item_image m-0">
                                        <img src={srImg3} alt="Service Details"/>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        </div>
                    </section>
                    <ContactSection />
                </main>
                <Footer />
                <Scrollbar />
                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="7e90gBu4pas" onClose={() => setOpen(false)} />
            </Fragment>
        </SEOWrapper>
    )
};
export default CSRPage;