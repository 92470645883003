import React, { Fragment, useState } from 'react';
import SEOWrapper from '../../components/SEOWrapper/SEOWrapper';
import Services from '../../api/service'
import { useParams } from 'react-router-dom'
import ModalVideo from 'react-modal-video'
import Header2 from '../../components/Header2/Header2';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import ProcessTechnology from '../../components/software-company-components/ProcessTechnology/ProcessTechnology';
import icon from '../../images/icons/icon_check_3.svg'
import ContactSection from '../../components/software-company-components/ContactSection';
import ArifEffect from '../Animations/ArifEffect';

const ServiceSinglePage = () => {

    const { slug } = useParams()

    const ServiceDetails = Services.find(item => item.slug === slug)

    const metaData = {
        title: `${ServiceDetails.title} Services | Invecent Private Limited`,
        description: `Discover our comprehensive ${ServiceDetails.title} services and solutions at Invecent Private Limited. From software development to cloud solutions, we deliver innovative technology solutions tailored to your business needs.`,
        keywords: `IT services, ${ServiceDetails.title}, software solutions, technology consulting, digital transformation, cloud services, enterprise solutions, custom software, IT infrastructure`,
        additionalTags: {
            'og:type': 'website',
            'article:section': `${ServiceDetails.title} Services`,
            'og:locale': 'en_US'
        }
    };

    const [isOpen, setOpen] = useState(false)

    return (
        <SEOWrapper metaData={metaData}>
            <Fragment>
                <Header2 />
                <main className="page_content service-single-page">
                    <PageTitle pageTitle={ServiceDetails.title} pagesub={'Details'} pageTop={'Services'} pageBg={ServiceDetails.bgImg}/>
                    <section className="service_details_section section_space bg-light">
                        <div className="container">
                            <ArifEffect variants={{ hidden: { opacity: 0, y: 100 }, peek: { opacity: 1, y: 0 } }}>
                            <div className="details_item_image position-relative">
                                <img src={ServiceDetails.sImg} alt="Service Details"/>
                            </div>
                            </ArifEffect>
                            <h2 className="details_item_title">{ServiceDetails.title}</h2>
                            <p>
                                {ServiceDetails.description}
                            </p>
                            <p>
                            At our IT solution agency, we specialize in crafting robust and reliable network architectures tailored to meet the unique needs of our clients. From small businesses to large enterprises, we understand the critical importance of a well-designed network infrastructure in driving efficiency, security, and scalability. Our team of experienced professionals works closely with clients.
                            </p>
                            <p>
                                We take a holistic approach to network design, considering factors such as bandwidth requirements, security protocols, scalability, and future growth potential. By leveraging industry best practices and cutting-edge technologies,
                            </p>
                            <h3 className="details_item_info_title">Services Outcome</h3>
                            <p className="mb-4">
                                Here are six key points that can be associated with a digital Transformation gallery case global Digital Systems Engineer Services leader helping Fortune 500 companies on their innovation agenda:
                            </p>
                            <div className="row mb-4">
                                <div className="col-lg-6">
                                    <ul className="icon_list unordered_list_block">
                                        {ServiceDetails.features.slice(0,3).map((feature, featureitem) => (
                                        <li key={featureitem}>
                                            <span className="icon_list_icon">
                                                <img src={icon} alt="Check SVG Icon"/>
                                            </span>
                                            <span className="icon_list_text">
                                                {feature}
                                            </span>
                                        </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul className="icon_list unordered_list_block">
                                    {ServiceDetails.features.slice(3,6).map((feature, featureitem) => (
                                        <li key={featureitem}>
                                            <span className="icon_list_icon">
                                                <img src={icon} alt="Check SVG Icon"/>
                                            </span>
                                            <span className="icon_list_text">
                                                {feature}
                                            </span>
                                        </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                <ArifEffect variants={{ hidden: { opacity: 0, x: -100 }, peek: { opacity: 1, x: 0 } }}>
                                    <div className="details_item_image m-0">
                                        <img src={ServiceDetails.subImg1} alt="Service Details" style={{ height: '45vh' }}/>
                                    </div>
                                </ArifEffect>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                <ArifEffect variants={{ hidden: { opacity: 0, x: 100 }, peek: { opacity: 1, x: 0 } }}>
                                    <div className="details_item_image m-0">
                                        <img src={ServiceDetails.subImg2} alt="Service Details" style={{ height: '45vh' }}/>
                                    </div>
                                </ArifEffect>
                                </div>
                            </div>
                        </div>
                        <ProcessTechnology />
                    </section>
                    <ContactSection />
                </main>
                <Footer />
                <Scrollbar />
                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="7e90gBu4pas" onClose={() => setOpen(false)} />
            </Fragment>
        </SEOWrapper>
    )
};
export default ServiceSinglePage;